

:root {
  --color-base: #000000;
  --color-background: #ffffff;
  --color-background-card: rgba(255,255,255, 0.9);
  --main-gradient: linear-gradient(0deg, rgba(167,0,255,1) 0%, rgba(242,0,255,1) 100%);
}

* {
  box-sizing: border-box;
}

html, body {
  font-family: -apple-system, BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 16px;
  min-height: 100%;
}

html, body, #root {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 100%;
  padding: 0;
  margin: 0;
}

body {
  justify-content: center;
}

h1 {
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  margin: 0 0 1rem;
}

body {
  background: var(--main-gradient);
}

main {
  background: var(--color-background);
  margin: 2rem;
  padding: 1rem;
  max-width: 60ch;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 40px rgba(0,0,0,0.4);

  @supports (backdrop-filter: blur(20px)) {
    background: var(--color-background-card);
    backdrop-filter: blur(40px);
  }

  form {
    display: grid;
    gap: 2rem;
    align-items: center;
    label {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;

      input {
        padding: .5rem 1rem;
        border-radius: 5px;
        border: none;
        font-size: 1rem;
        background: rgba(255,255,255, 0.6);

        &:focus {
          background: rgba(255,255,255,1);
        }
      }
    }
  }
}

.result {
  display: grid;
  align-items: center;
  justify-content: center;
  span:nth-child(2) {
    font-weight: 600;
    font-size: 2rem;
  }
}
